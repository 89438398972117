import axios from 'axios';
export const state = {};

const apiURL = 'https://api.calsmal.com/winedup';

export const readWine = async () => {
  try {
    const url = apiURL;
    const { data } = await (await fetch(url)).json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const createWine = async (data) => {
  try {
    const res = await axios({
      method: 'POST',
      url: apiURL,
      data,
    });

    console.log(res);
    if (res.data.status === 'success') {
      return 'success';
    }
    // console.log(res);
  } catch (err) {
    console.error('error', err.response.data.message);
    if (err.response.data.message.split(' ')[0] === 'E11000')
      return 'Error: Duplicate barcode! Please try again.';
    return err.response.data.message;
  }
};

export const updateWine = async () => {
  // to do
};

export const deleteWine = async (id) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `${apiURL}/${id}`,
    });
    if (res.status === 204) {
      return 'success';
    }
  } catch (err) {
    return err.response.data.message;
  }
};
