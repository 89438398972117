import { showAlert } from './helpers';

// Div elements
const home = document.querySelector('.home');
const edit = document.querySelector('.edit');
const viewSingle = document.querySelector('.view-single');
const viewSingleWine = document.querySelector('.view-single__wine');
const viewList = document.querySelector('.view-list');
let listWine; // variable for wine containers

// Buttons
const btnNew = document.querySelector('.btn--start');
const btnCancel = document.querySelector('.btn--cancel');
const btnViewWine = document.querySelector('.btn--view-wine');
const btnHome = document.querySelector('.btn--home');
const btnDelete = document.querySelector('.btn--delete');
// const btnEdit = document.querySelector('.btn--edit');
const btnSubmit = document.querySelector('.btn--submit');

// Form
const wineForm = document.getElementById('wineForm');
const searchInput = document.getElementById('search-input');

// Globals
let wineLookup; // used for storing wine information in app
let currentBarcode;

// New wine - search not found
btnNew.addEventListener('click', () => {
  resetSearch();
  hidden([home]);
  visible([edit]);
});

// btnViewWine.addEventListener('click', () => renderSingleWine(currentBarcode));

// Go home
btnHome.addEventListener('click', () => {
  hidden([viewSingle]);
  visible([home]);
});

// btnEdit.addEventListener('click', () => {
//   console.log('edit');
// });

// Cancel new wine
btnCancel.addEventListener('click', () => {
  hidden([edit]);
  visible([home]);
});

// Complex search input event handler
const loadSearch = () => {
  searchInput.addEventListener('keyup', () => {
    const searchTerm = searchInput.value;
    listWine.forEach((wine) => {
      if (
        !wine.dataset.barcode.includes(+searchTerm) &&
        !wine.dataset.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !wine.dataset.varietal
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) &&
        !wine.dataset.comments.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        wine.classList.add('hidden');
      } else {
        wine.classList.remove('hidden');
      }
    });
  });
};

const resetSearch = () => {
  searchInput.value = '';
  listWine.forEach((wine) => {
    wine.classList.remove('hidden');
  });
};

// Form event handler - gets submitFunction from controller submit handler
export const addSubmitHandler = (submitFunction) => {
  wineForm.addEventListener('submit', async (event) => {
    event.preventDefault();
    btnSubmit.value = 'sending...';
    const formData = new FormData(wineForm);
    wineForm.reset();
    const res = await submitFunction(formData);
    btnSubmit.value = 'done';
    if ((await res) === 'success') {
      showAlert('success', 'successfully added!');
      window.setTimeout(() => {
        location.reload();
      }, 3000);
    } else {
      showAlert('error', res);
    }
  });
};

// Delete events handler gets deleteFunction from controller
export const addDeleteHandler = (deleteFunction) => {
  btnDelete.addEventListener('click', async () => {
    btnDelete.value = 'deleting...';
    const res = await deleteFunction(btnDelete.dataset.id);
    btnDelete.value = '🗑️ delete';
    if ((await res) === 'success') {
      showAlert('success', 'successfully deleted!');
      window.setTimeout(() => {
        location.reload();
      }, 3000);
    } else {
      showAlert('error', 'error, please try again later');
    }
  });
};

// View functions
export const renderFullList = (data) => {
  viewList.innerHTML = '';
  wineLookup = [...data];
  data.map((wine) => {
    let review = '';
    for (let i = 1; i <= +wine.review; i++) {
      review += '⭐ ';
    }
    let html = `
    <div class="list-wine" data-barcode="${wine.barcode}" data-name="${wine.name}" data-varietal="${wine.varietal}" data-comments="${wine.comments}">
    <div class="list-wine__item list-wine__name">🍷 ${wine.name}</div>
    <div class="list-wine__item list-wine__number mono">🏷️ ${wine.barcode}</div>
    <div class="list-wine__item list-wine__review">${review}</div>
    </div>
    `;
    viewList.insertAdjacentHTML('beforeend', html);
  });
  listWine = document.querySelectorAll('.list-wine');
  listWine.forEach((el) =>
    el.addEventListener('click', function (event) {
      const barcode = event.target
        .closest('.list-wine')
        .getAttribute('data-barcode');
      renderSingleWine(barcode);
      resetSearch();
    })
  );
  loadSearch();
};

const renderSingleWine = (barcode) => {
  hidden([home]);
  visible([viewSingle]);
  const wine = wineLookup.find((wine) => +wine.barcode === +barcode);
  let review = '';
  for (let i = 1; i <= +wine.review; i++) {
    review += '⭐ ';
  }
  const html = `
    <h2 class="h2--single-wine">🍷 ${wine.name}<br />${review}</h2>
    <p>🍇 ${wine.varietal}</p>
    <p>💬 Comments</p>
    <p class="wine-comments">${wine.comments}</p>
    </p>
    <p class="mono">🏷️ #${wine.barcode}</p>
  </div>
    `;
  viewSingleWine.innerHTML = '';
  viewSingleWine.insertAdjacentHTML('afterBegin', html);
  btnDelete.dataset.id = wine._id;
};

// Add 'hidden' class to elements
const hidden = (divEl) => {
  divEl.forEach((el) => el.classList.add('hidden'));
};

// Remove 'hidden' class from elements
const visible = (divEl) => {
  divEl.forEach((el) => el.classList.remove('hidden'));
};
