import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as model from './model';
import * as view from './view';

if (module.hot) {
  module.hot.accept();
}

// Application control architecture

const loadWines = async function () {
  const { data } = await model.readWine();
  view.renderFullList(data);
};

const wineHandler = async (formData, event = 'create') => {
  const formDataObject = {};
  formData.forEach(function (value, key) {
    formDataObject[key] = value;
  });
  const createdWine = await model.createWine(formDataObject);
  return createdWine;
};

const deleteHandler = async (id) => {
  const deletedWine = await model.deleteWine(id);
  return deletedWine;
};

const init = function () {
  loadWines();
  view.addSubmitHandler(wineHandler);
  view.addDeleteHandler(deleteHandler);
};

init();
